<template>
    <div>
        <h1>Visa bokning</h1>
    </div>
</template>

<script>
export default {
    name: 'ViewBooking'
}
</script>
