<template>
    <div class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <div v-if="!isLoading" class="content">
                    <h1 class="title">
                        <span>
                            {{
                                $t('booking.bookingWithId', {
                                    booking: booking.fullAddress
                                })
                            }}
                        </span>
                    </h1>
                    <p class="subtitle">
                        {{
                            $t('booking.customerAndOffice', {
                                customer: booking.customer.fullName,
                                office: booking.customerUsergroup.name
                            })
                        }}
                    </p>
                </div>
                <div
                    v-if="
                        !isLoading && booking.status.event === 'cancel_request'
                    "
                    class="content"
                >
                    <p class="has-text-centered">
                        {{
                            $t('booking.edit.cancelRequest', {
                                cancelledAt: cancelRequested
                            })
                        }}
                    </p>
                </div>

                <ViewBooking v-if="!isLoading" :existing-booking="booking" />
            </div>
        </div>
    </div>
</template>

<script>
import ViewBooking from '../../components/booking/BookingMobile'
import format from '@/_lib/format'

export default {
    name: 'EditBooking',
    components: { ViewBooking },
    data() {
        return {}
    },
    computed: {
        isLoading() {
            return this.$store.getters['booking/isLoading']
        },
        booking() {
            return this.$store.getters['booking/booking']
        },
        cancelRequested() {
            return format(this.booking.cancelledAt, 'd MMM HH:mm')
        }
    },
    created() {
        this.$store.dispatch('booking/view', this.$route.params.uuid)
    }
}
</script>

<style scoped>
.is-four-fifths {
    margin: 0 auto;
}
</style>
